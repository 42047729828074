import { areAddressesEqual } from './addressComparison.util';
import { keyBy, cloneDeep } from 'lodash';
import { isCoBorrowerAccountOffered, isCoBorrowerJointAccount, didSpouseProvideSecurityAnswer, LoanUtils } from 'src/app/shared/utils/loan-utils';
import {
  RealtorTypeEnum,
  ICostViewModel,
  MilitaryServiceTypeEnum,
  EmploymentVerificationTypeEnum,
  URLAFormTypeEnum,
  LoanPurposeTypeEnum,
  HomeBuyingTypeEnum,
  DomesticRelationshipRightsEnum,
  ICPOSLoan,
  IAssetViewModel,
  AssetTypeEnum,
  ICPOSEmployment,
  IIncomeInfoViewModel,
  EmploymentTypeEnum,
  CPOSIncomeTypeEnum,
  EmploymentStatusTypeEnum,
  SelectOneYesNoEnum,
  IncomeTypeEnum,
  PeriodTypeEnum,
  SelfEmploymentEntityTypeEnum,
  SelfEmploymentCloverEntityTypeEnum,
  SelfEmploymentCloverEntityTypeLLCEnum,
  ILoanViewModel,
  BankruptcyTypeEnum,
  PropertyTypeEnum,
  MaritalStatusTypeEnum,
  BusinessContactPhoneTypeEnum,
  NullableBooleanEnum,
  IBorrowerCounselingViewModel,
  CounselingTypeEnum,
  CounselingProviderTypeEnum,
  CounselingFormatTypeEnum,
  CounselingWorkshopTypeEnum,
  IBorrowerViewModel,
  PreferredLanguageTypeEnum
} from 'src/app/shared/models';

/**
 * Map the loan model to the format needed by the form builder
 * @param loanModel
 * @param models
 */
export function mapLoanToFormBuilder(
  loanModel: ILoanViewModel,
  models: { [key: string]: any },
  loanApplicationId: string,
  clientId: string,
): ILoanViewModel {
  if (!loanModel) {
    return;
  }
  // Clone deep to avoid mutation, map to sane loan model
  const loanModelMapped: ILoanViewModel = cloneDeep(loanModel as ILoanViewModel);

  // Add default model for loan participants
  if (
    !(
      loanModelMapped.loanParticipants &&
      loanModelMapped.loanParticipants.loanParticipants &&
      loanModelMapped.loanParticipants.loanParticipants.length
    )
  ) {
    loanModelMapped.loanParticipants.loanParticipants = [];
  }

  let haveREAgent = false;
  let reAgentName = '';
  let reAgentEmail = '';
  let reAgentPhone = '';

  let agentLoanParticipant = loanModelMapped.loanParticipants.loanParticipants.find(
    lp => lp.realtorType === RealtorTypeEnum.BuyersAgent,
  );
  if (agentLoanParticipant) {
    haveREAgent = true;
    reAgentName = agentLoanParticipant.contactFullname;

    let reEmailAddress = agentLoanParticipant.emailAddressList.list.find(
      (emailAddress: any) => emailAddress.isPreferred === true,
    );
    if (!reEmailAddress || !reEmailAddress.value.emailAddress || reEmailAddress.value.emailAddress === '') {
      reEmailAddress = agentLoanParticipant.emailAddressList.list.find(
        (emailAddress: any) => emailAddress.value.emailAddress && emailAddress.value.emailAddress !== '',
      );
    }
    if (reEmailAddress) {
      reAgentEmail = reEmailAddress.value.emailAddress;
    }

    let rePhone = agentLoanParticipant.phoneList.list.find((phone: any) => phone.value.phoneNumberType === 3);
    if (!rePhone || !rePhone.value.phoneNumber || rePhone.value.phoneNumber === '') {
      rePhone = agentLoanParticipant.phoneList.list.find((phone: any) => phone.isPreferred === true);
    }
    if (!rePhone || !rePhone.value.phoneNumber || rePhone.value.phoneNumber === '') {
      rePhone = agentLoanParticipant.phoneList.list.find(
        (phone: any) => phone.value.phoneNumber && phone.value.phoneNumber !== '',
      );
    }
    if (rePhone) {
      reAgentPhone = rePhone.value.phoneNumber;
    }
  } else {
    agentLoanParticipant = cloneDeep(models['loanParticipants.loanParticipants']);
  }

  // loanModelMapped.closingCost.costs = [];
  // Default closing costs are required for credit REOs. Check if empty and then add
  if (!loanModelMapped.closingCost.costs.length) {
    loanModelMapped.closingCost.costs = (<ICostViewModel[]>cloneDeep(models['closingCost.costs'])).map(cost => {
      cost.loanId = loanModelMapped.loanId;
      return cost;
    });
  }

  // Add computed properties that aren't part of the form model
  // Props like isMilitary is needed for routing but needs to be inferred from military service type
  loanModelMapped.$$custom = {
    assetType: null, // Used for routing on the assets page
    assetIdActive: null, // Used for routing on the assets mixpanel
    isEVerifyAsset: null, // Used for routing on the assets eVOA page
    isEVerifyIncomeEmployment: null, // Used for routing on the income eVOI/eVOE page
    isEVerifyIncomeEmploymentSpouse: null, // Used for routing on the income eVOI/eVOE page
    isEditing: null,
    loanOfficer: {
      email: null,
      name: null,
      phone: null,
      branch: null,
      leadSourceId: null,
      loanContactType: null,
      nmlsNumber: null,
      pictureData: null,
      title: null,
      userAccountId: null,
    },
    creditResponse: null,
    noAssets: null,
    nonSpouseCoborrower: false,
    borrowerActive: (<any>loanModelMapped).transactionInfo.borrowers[0], // Always default active borrower to first borrower
    borrowerRegistrationOffered: null,
    spouseSecurityAnswerProvided: null,
    borrowerJointAccount: null,
    nps: null,
    bankrupcyType: null,
    bankrupcyType2: null,
    jointCredit: null,
    jointCredit2: null,
    ssn: loanModelMapped.transactionInfo.borrowers[0].ssn,
    ssnSpouse: loanModelMapped.transactionInfo.borrowers[1].ssn,
    isRunCreditAuthorizedSpouse: null,
    isRunEvoiEvoeAuthorized: null,
    isRunEvoiEvoeAuthorizedSpouse: null,
    isMilitary:
      loanModelMapped.transactionInfo.borrowers[0].militaryServiceType &&
        loanModelMapped.transactionInfo.borrowers[0].militaryServiceType !== MilitaryServiceTypeEnum.None ||
      loanModelMapped.transactionInfo.borrowers[0].survivingSpouse
        ? true
        : false,
    isMilitarySpouse:
      loanModelMapped.transactionInfo.borrowers[1].militaryServiceType &&
        loanModelMapped.transactionInfo.borrowers[1].militaryServiceType !== MilitaryServiceTypeEnum.None ||
      loanModelMapped.transactionInfo.borrowers[1].survivingSpouse
        ? true
        : false,
    incomeType: null,
    signedPurchaseAgreement: null,
    haveREAgent: haveREAgent,
    agentLoanParticipant: agentLoanParticipant,
    agentName: reAgentName,
    agentEmail: reAgentEmail,
    agentPhone: reAgentPhone,
    zipLookupResponse: null,
    primaryBorrowerIncomeComplete: false,
    recordCreditAuth: false,
    creditAuthAudited: false,
    subjectPropertyOccupancyType: null,
    frozenCreditScore: false,
    employmentVerificationType: EmploymentVerificationTypeEnum.None,
    showVoiVoeAuthInCredit: null,
    twnFailed: null,
    twnFailedSpouse: null,
    twnFailedInCreditSection: null,
    twnFailedInCreditSectionSpouse: null,
    alimonyEtc: {
      hasAlimony: null,
      alimonyMonthlyAmount: null,
      ChildSupportMonthlyAmount: null,
      SeperateMaintenanceMonthlyAmount: null,
      hasAlimonySpouse: null,
      alimonyMonthlyAmountSpouse: null,
      ChildSupportMonthlyAmountSpouse: null,
      SeperateMaintenanceMonthlyAmountSpouse: null,
      alimonyMiscellaneousDebtId: null,
      childSupportMiscellaneousDebtId: null,
      seperateMiscellaneousDebtId: null,
      alimonyMiscellaneousDebtIdSpouse: null,
      childSupportMiscellaneousDebtIdSpouse: null,
      seperateMiscellaneousDebtIdSpouse: null,
    },
    additionalREOsPreVal: null,
    additionalREOsPreValSpouse: null,
    useEVOA: null,
    evoaAggreedTermsOfService: false,
    evoaTermsOfServiceUrl: null,
    showSecurityQuestionCoBorrowerPage: null,
    securityQuestionId: null,
    inviteAdditionalApplicantEnabled: false,
    isChildAppOriginatedByBorrower: true,
    // Set defaults for mapped loan model
    loan: setDefaults(loanModel, models),
    stub: {
      a: null,
      b: null,
      c: null,
      d: null,
      e: null,
      f: null,
      g: null,
    },
    programTypeBorrower: 0,
    programTypeCoBorrower: 0,
    ssoUser: false,
    isEmploymentInformationEmpty: false
  };

  if(loanModelMapped.transactionInfo.borrowers[0].preferredLanguage == null) {
    loanModelMapped.transactionInfo.borrowers[0].preferredLanguage = PreferredLanguageTypeEnum.SelectOne;
  }

  if(loanModelMapped.transactionInfo.borrowers[1].preferredLanguage == null) {
    loanModelMapped.transactionInfo.borrowers[1].preferredLanguage = PreferredLanguageTypeEnum.SelectOne;
  }

  if (loanModelMapped.transactionInfo.borrowers[0].borrowerHousingCounseling == null) {
    loanModelMapped.transactionInfo.borrowers[0].borrowerHousingCounseling = createCounselingModel(loanModelMapped.transactionInfo.borrowers[0].borrowerId , CounselingTypeEnum.Housing);
  }

  if (loanModelMapped.transactionInfo.borrowers[0].borrowerEducationCounseling == null) {
    loanModelMapped.transactionInfo.borrowers[0].borrowerEducationCounseling = createCounselingModel(loanModelMapped.transactionInfo.borrowers[0].borrowerId , CounselingTypeEnum.Education);
  }
  loanModelMapped.$$custom.programTypeBorrower = getProgramType(loanModelMapped.transactionInfo.borrowers[0]);

  if(loanModelMapped.transactionInfo.borrowers[1]) {
    if (loanModelMapped.transactionInfo.borrowers[1].borrowerHousingCounseling == null) {
      loanModelMapped.transactionInfo.borrowers[1].borrowerHousingCounseling = createCounselingModel(loanModelMapped.transactionInfo.borrowers[1].borrowerId , CounselingTypeEnum.Housing);
    }
    if (loanModelMapped.transactionInfo.borrowers[1].borrowerEducationCounseling == null) {
      loanModelMapped.transactionInfo.borrowers[1].borrowerEducationCounseling = createCounselingModel(loanModelMapped.transactionInfo.borrowers[1].borrowerId , CounselingTypeEnum.Education);
    }
    loanModelMapped.$$custom.programTypeCoBorrower = getProgramType(loanModelMapped.transactionInfo.borrowers[1]);
  }
  // If liabilities are found in the loan model
  if (loanModelMapped.transactionInfo.liabilities.length) {
    loanModelMapped.$$custom.loan.liabilities = loanModelMapped.transactionInfo.liabilities
      // Only grab mortgages
      .filter(x => x.typeId === 1)
      .map(x => {
        // Loop through the property expenses which is used to determine what value for the selected impound
        // This value is set by looking at the combination of what prop expenses have the impounded flag set
        if (x.property && x.property.propertyExpenses) {
          const record: Record<string, boolean> = {};
          x.property.propertyExpenses.forEach(exp => (record[exp.type] = exp.impounded));

          /** 2009 URLA */
          if (loanModel.urlaFormType === URLAFormTypeEnum.URLA2009) {
            // Taxes and insurance, selectedImpound = 0
            if (record['1'] && record['2'] && record['3'] && record['5']) {
              x.selectedImpound = 0;
              // Insurance Only, selectedImpound = 2
            } else if (record['2'] && record['3'] && record['5']) {
              x.selectedImpound = 2;
              // Taxes only, selectedImpound = 1
            } else if (record['1'] && record['3']) {
              x.selectedImpound = 1;
              // No Impounds, selectedImpound = 3
            } else if (record['3']) {
              x.selectedImpound = 3;
            }
            /** 2020 URLA */
          } else {
            // Taxes and insurance, selectedImpound = 0
            if (record['1'] && record['2'] && record['5']) {
              x.selectedImpound = 0;
              // Insurance Only, selectedImpound = 2
            } else if (record['2'] && record['5']) {
              x.selectedImpound = 2;
              // Taxes only, selectedImpound = 1
            } else if (record['1']) {
              x.selectedImpound = 1;
              // No Impounds, selectedImpound = 3
            } else {
              x.selectedImpound = 3;
            }
          }
        }
        return x;
      });
  }

  loanModelMapped.$$custom.alimonyEtc.hasAlimony =
    loanModelMapped.transactionInfo.borrowers[0].borrowerDetail.hasToPayForAlimony;

  // If AlimonyEtc. is found in the borrower "[0]" loan model
  if (loanModelMapped && loanModelMapped.transactionInfo.borrowers[0].miscellaneousDebt.length) {
    // If borrower has Alimony, ChildSupport or SeperateMaintenance miscellaneous debt, set the flag to TRUE
    if (
      loanModelMapped.transactionInfo.borrowers[0].miscellaneousDebt.find(
        miscDebt => !miscDebt.isRemoved && (miscDebt.typeId === 4 || miscDebt.typeId === 5 || miscDebt.typeId === 6)
      )
    ) {
      loanModelMapped.$$custom.alimonyEtc.hasAlimony = true;
    }

    loanModelMapped.transactionInfo.borrowers[0].miscellaneousDebt.forEach(miscDebtItem => {
      if (miscDebtItem.isRemoved) {
        return;
      }

      if (miscDebtItem.typeId === 4) {
        loanModelMapped.$$custom.alimonyEtc.alimonyMonthlyAmount = miscDebtItem.amount;
      } else if (miscDebtItem.typeId === 5) {
        loanModelMapped.$$custom.alimonyEtc.ChildSupportMonthlyAmount = miscDebtItem.amount;
      } else if (miscDebtItem.typeId === 6) {
        loanModelMapped.$$custom.alimonyEtc.SeperateMaintenanceMonthlyAmount = miscDebtItem.amount;
      }
    });
  }

  loanModelMapped.$$custom.alimonyEtc.hasAlimonySpouse =
    loanModelMapped.transactionInfo.borrowers[1].borrowerDetail.hasToPayForAlimony;

  // If AlimonyEtc. is found in the spouse "[1]" loan model
  if (loanModelMapped && loanModelMapped.transactionInfo.borrowers[1].miscellaneousDebt.length) {
    // If co-borrower has Alimony, ChildSupport or SeperateMaintenance miscellaneous debt, set the flag to TRUE
    if (
      loanModelMapped.transactionInfo.borrowers[1].miscellaneousDebt.find(
        miscDebt => !miscDebt.isRemoved && (miscDebt.typeId === 4 || miscDebt.typeId === 5 || miscDebt.typeId === 6)
      )
    ) {
      loanModelMapped.$$custom.alimonyEtc.hasAlimonySpouse = true;
    }

    loanModelMapped.transactionInfo.borrowers[1].miscellaneousDebt.forEach(miscDebtItem => {
      if (miscDebtItem.isRemoved) {
        return;
      }

      if (miscDebtItem.typeId === 4) {
        loanModelMapped.$$custom.alimonyEtc.alimonyMonthlyAmountSpouse = miscDebtItem.amount;
      } else if (miscDebtItem.typeId === 5) {
        loanModelMapped.$$custom.alimonyEtc.ChildSupportMonthlyAmountSpouse = miscDebtItem.amount;
      } else if (miscDebtItem.typeId === 6) {
        loanModelMapped.$$custom.alimonyEtc.SeperateMaintenanceMonthlyAmountSpouse = miscDebtItem.amount;
      }
    });
  }

  // Buying stage
  if (
    clientId !== 'env' &&
    loanModelMapped.loanPurposeType === LoanPurposeTypeEnum.Purchase &&
    loanModelMapped.homeBuyingType === HomeBuyingTypeEnum.OfferAccepted
  ) {
    loanModelMapped.$$custom.signedPurchaseAgreement = true;
  }

  const [borrowerPrimary, borrowerSecondary] = loanModelMapped.transactionInfo.borrowers;

  if (borrowerPrimary.maritalStatus !== MaritalStatusTypeEnum.Married
    && borrowerPrimary.maritalStatus !== MaritalStatusTypeEnum.Separated) {
    loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan = false;
  }

  // Map DOB's to format needed by browser date control (from MM/DD/YYYY to YYYY-MM-DD)
  borrowerPrimary.dateOfBirth = adjustDob(borrowerPrimary.dateOfBirth);
  borrowerSecondary.dateOfBirth = adjustDob(borrowerSecondary.dateOfBirth);

  const haveSameUsernames = borrowerPrimary.userAccount.username === borrowerSecondary.userAccount.username;

  const borrowerRegistrationOffered = (loanModelMapped.$$custom.borrowerRegistrationOffered =
    isCoBorrowerAccountOffered(loanModelMapped, loanApplicationId) || (!haveSameUsernames && didSpouseProvideSecurityAnswer(loanModelMapped)));
  if (borrowerRegistrationOffered) {
    loanModelMapped.$$custom.borrowerJointAccount = isCoBorrowerJointAccount(loanModelMapped, loanApplicationId);
  }

  loanModelMapped.$$custom.spouseSecurityAnswerProvided = didSpouseProvideSecurityAnswer(loanModelMapped);

  loanModelMapped.$$custom.nonSpouseCoborrower =
    loanModelMapped.transactionInfo.loanApplications[0].isPrimary === false;

  if (loanModelMapped.additionalApplicant) {
    loanModelMapped.$$custom.isChildAppOriginatedByBorrower = loanModelMapped.additionalApplicant.isOriginatedByBorrower;
  }

  const [borrower0] = loanModelMapped.transactionInfo.borrowers;
  switch (borrower0.maritalStatus) {
    case 0: // married
    case 1: // separated
    case 2: // unmarried
      break;
    case 3: // civil union
    case 4: // domestic partnership
    case 5: // registered reciprocal beneficiary relationship
    case 6: // other
      const { borrowerPrimary } = loanModelMapped.$$custom.loan;
      borrowerPrimary.maritalStatusOther = borrower0.maritalStatus - 2;
      borrower0.maritalStatus = 3;
      break;
  }

  const DPTC_Other = 19;
  const knownDptcs = [1, 4, 6, 12, 7, 11, DPTC_Other];
  if (
    loanModelMapped.financialInfo.downPaymentTypeCode !== null &&
    !knownDptcs.includes(loanModelMapped.financialInfo.downPaymentTypeCode)
  ) {
    loanModelMapped.financialInfo.downPaymentTypeCode = DPTC_Other;
  }

  mapPropertiesToFormBuilder(loanModelMapped);
  mapAssetsToFormBuilder(loanModelMapped, models);
  mapEmploymentsToFormBuilder(loanModelMapped, models);
  mapDeclarationsToFormBuilder(loanModelMapped);

  /**
   * Fill in default borrower items, if not present
   */
  loanModelMapped.transactionInfo.borrowers.forEach(borrower => {
    // Make sure a phones data type is present for each borrower
    if (!borrower.phones.length) {
      borrower.phones.push(models['loan.transactionInfo.borrowers.phones']);
    }
    // Make sure a default asset is available
    if (!borrower.assets.length) {
      borrower.assets.push(models['loan.transactionInfo.borrowers.assets']);
    }
  });

  // Set securityQuestionId to null if it is equal to -1
  if (
    loanModelMapped.transactionInfo.borrowers[1].userAccount.securityQuestionId === 0 &&
    (!loanModelMapped.transactionInfo.borrowers[1].userAccount.securityAnswer ||
      loanModelMapped.transactionInfo.borrowers[1].userAccount.securityAnswer === '')
  ) {
    loanModelMapped.$$custom.securityQuestionId = null;
  }

  if (
    loanModelMapped.transactionInfo.borrowers[1].borrowerDetail.welcomeEmailRequired === null &&
    (!loanModelMapped.transactionInfo.borrowers[1].userAccount.securityAnswer ||
      loanModelMapped.transactionInfo.borrowers[1].userAccount.securityAnswer === '')
  ) {
    loanModelMapped.$$custom.showSecurityQuestionCoBorrowerPage = true;
  } else {
    loanModelMapped.$$custom.showSecurityQuestionCoBorrowerPage = false;
  }

  //Set maritalStatus and subjectPropertyOccupancyType to null if it is equal to -1
  if (loanModelMapped.transactionInfo.borrowers[0].maritalStatus == -1) {
    loanModelMapped.transactionInfo.borrowers[0].maritalStatus = null;
  }
  if (loanModelMapped.transactionInfo.loanApplications[0].occupancyType == 0) {
    loanModelMapped.$$custom.subjectPropertyOccupancyType = null;
  }

  //Set default address to null
  if (loanModelMapped.$$custom.loan.addressSubject.streetName == 'TBD') {
    loanModelMapped.$$custom.loan.addressSubject.streetName = null;
  }

  const { addressSubject } = loanModelMapped.$$custom.loan;
  if (addressSubject.pudIndicator === 0) {
    addressSubject.pudIndicator = null;
  }
  //Urla 2009 - default units to 2, Urla 2020 - default units to null
  if ((addressSubject.numberOfUnits < 2 || 4 < addressSubject.numberOfUnits) && addressSubject.projectType === PropertyTypeEnum.MultiFamilyTwoToFourUnits) {
    addressSubject.numberOfUnits = LoanUtils.isURLA2020(loanModelMapped.urlaFormType) ? null : 2;
  }

  loanModelMapped.transactionInfo.borrowers.forEach(b => {
    if (b.domesticRelationshipRights === DomesticRelationshipRightsEnum.SelectOne) {
      b.domesticRelationshipRights = null;
    }
  });

  mapAdditionalApplicant(loanModelMapped);

  return loanModelMapped;
}

/**
 * Set the default models needed by the clover loan model
 * @param loanModel
 * @param models
 */
function setDefaults(loanModel: ILoanViewModel, models: { [key: string]: any }) {
  const cvLoan: ICPOSLoan = {
    addressSubject: { ...models['loan.transactionInfo.properties'] },
    assets: [],
    downPaymentAsset: { ...models['loan.transactionInfo.borrowers.assets'] },
    liabilities: [models['loan.transactionInfo.liabilities']],
    employments: [],
    incomes: [],
    borrowerPrimary: {
      ...loanModel.transactionInfo.borrowers[0],
      isAddressSameAsPrimaryBorrower: false,
      addressCurrent: {
        ...models['loan.transactionInfo.properties'],
        borrowerId: loanModel.transactionInfo.borrowers[0].borrowerId,
      },
      isMailingAddressSameAsCurrent: true,
      addressMailing: {
        ...models['loan.transactionInfo.properties'],
        borrowerId: loanModel.transactionInfo.borrowers[0].borrowerId,
      },
      addressHistory: {
        ...models['loan.transactionInfo.properties'],
        borrowerId: loanModel.transactionInfo.borrowers[0].borrowerId,
      },
      maritalStatusOther: null,
      usernameStaged: loanModel.transactionInfo.borrowers[0].userAccount.username,
    },
    borrowerSecondary: {
      ...loanModel.transactionInfo.borrowers[1],
      isAddressSameAsPrimaryBorrower: true,
      addressCurrent: {
        ...models['loan.transactionInfo.properties'],
        borrowerId: loanModel.transactionInfo.borrowers[1].borrowerId,
      },
      isMailingAddressSameAsCurrent: true,
      addressMailing: {
        ...models['loan.transactionInfo.properties'],
        borrowerId: loanModel.transactionInfo.borrowers[1].borrowerId,
      },
      addressHistory: {
        ...models['loan.transactionInfo.properties'],
        borrowerId: loanModel.transactionInfo.borrowers[1].borrowerId,
      },
      usernameStaged: loanModel.transactionInfo.borrowers[1].userAccount.username,
    },
    financialInfo: {
      ...loanModel.financialInfo,
      downPaymentTypeCodeOriginal: loanModel.financialInfo.downPaymentTypeCode,
    },
    additionalApplicant: {
      firstName: '',
      isOnlineUser: false,
      lastName: '',
      loanApplicationId: null,
      middleName: '',
      phoneNumber: '',
      phoneType: BusinessContactPhoneTypeEnum.Cell,
      securityAnswer: '',
      securityQuestionId: null,
      suffix: '',
      userAccountId: null,
      username: '',
    }
  };

  return cvLoan;
}

/**
 * Extracts individual properties out of a flat list
 * @param loanModel
 */
function mapPropertiesToFormBuilder(loanModel: ILoanViewModel) {
  loanModel.properties.forEach(property => {
    // Subject property
    if (property.isSubjectProperty) {
      loanModel.$$custom.loan.addressSubject = {
        ...loanModel.$$custom.loan.addressSubject,
        ...property,
        displayUnitNumber: (loanModel.$$custom.loan.addressSubject && loanModel.$$custom.loan.addressSubject.unitNumber && loanModel.$$custom.loan.addressSubject.unitNumber.length > 0) ? '#' + loanModel.$$custom.loan.addressSubject.unitNumber : '',
        displayStreetName: (loanModel.$$custom.loan.addressSubject && loanModel.$$custom.loan.addressSubject.streetName && loanModel.$$custom.loan.addressSubject.streetName.length > 0) ? loanModel.$$custom.loan.addressSubject.unitNumber : ''
      };
      loanModel.$$custom.loan.isSubjectPropertyInValid = false;
      loanModel.$$custom.loan.originalSubjectProeprty = {
        ...loanModel.$$custom.loan.originalSubjectProeprty,
        ...property,
        displayUnitNumber: (loanModel.$$custom.loan.originalSubjectProeprty && loanModel.$$custom.loan.originalSubjectProeprty.unitNumber && loanModel.$$custom.loan.originalSubjectProeprty.unitNumber.length > 0) ? '#' + loanModel.$$custom.loan.originalSubjectProeprty.unitNumber : '',
        displayStreetName: (loanModel.$$custom.loan.originalSubjectProeprty && loanModel.$$custom.loan.originalSubjectProeprty.streetName && loanModel.$$custom.loan.originalSubjectProeprty.streetName.length > 0) ? loanModel.$$custom.loan.originalSubjectProeprty.unitNumber : ''
      };

      // Store subject property. Use the loan application occupancy type if available
      // Fallback to subject property type of unavailable
      loanModel.$$custom.subjectPropertyOccupancyType =
        loanModel.transactionInfo.loanApplications[0].occupancyType != null
          ? loanModel.transactionInfo.loanApplications[0].occupancyType
          : property.occupancyType;
    }

    // Primary borrower current address
    if (property.propertyId === loanModel.$$custom.loan.borrowerPrimary.currentAddressId) {
      loanModel.$$custom.loan.borrowerPrimary.addressCurrent = {
        ...loanModel.$$custom.loan.borrowerPrimary.addressCurrent,
        ...property,
        borrowerId: loanModel.$$custom.loan.borrowerPrimary.borrowerId,
        displayUnitNumber: (loanModel.$$custom.loan.borrowerPrimary.addressCurrent.unitNumber && loanModel.$$custom.loan.borrowerPrimary.addressCurrent.unitNumber.length > 0) ? '#' + loanModel.$$custom.loan.borrowerPrimary.addressCurrent.unitNumber : '',
        displayStreetName: (loanModel.$$custom.loan.borrowerPrimary.addressCurrent.streetName && loanModel.$$custom.loan.borrowerPrimary.addressCurrent.streetName.length > 0) ? loanModel.$$custom.loan.borrowerPrimary.addressCurrent.streetName : ''
      };
    }

    // Primary borrower mailing address
    if (property.propertyId === loanModel.$$custom.loan.borrowerPrimary.mailingAddressId) {
      loanModel.$$custom.loan.borrowerPrimary.addressMailing = {
        ...loanModel.$$custom.loan.borrowerPrimary.addressMailing,
        ...property,
        borrowerId: loanModel.$$custom.loan.borrowerPrimary.borrowerId
      };
    }

    // Primary borrower previous address
    if (
      loanModel.$$custom.loan.borrowerPrimary.previousAddressIds &&
      loanModel.$$custom.loan.borrowerPrimary.previousAddressIds.length > 0
    ) {
      if (property.propertyId === loanModel.$$custom.loan.borrowerPrimary.previousAddressIds[0]) {
        loanModel.$$custom.loan.borrowerPrimary.addressHistory = {
          ...loanModel.$$custom.loan.borrowerPrimary.addressHistory,
          ...property,
          borrowerId: loanModel.$$custom.loan.borrowerPrimary.borrowerId,
          displayUnitNumber: (loanModel.$$custom.loan.borrowerPrimary.addressHistory.unitNumber && loanModel.$$custom.loan.borrowerPrimary.addressHistory.unitNumber.length > 0)
            ? '#' + loanModel.$$custom.loan.borrowerPrimary.addressHistory.unitNumber : '',
          displayStreetName: (loanModel.$$custom.loan.borrowerPrimary.addressHistory.streetName && loanModel.$$custom.loan.borrowerPrimary.addressHistory.streetName.length > 0)
            ? loanModel.$$custom.loan.borrowerPrimary.addressHistory.streetName: ''
        };
      }
    }

    // Secondary borrower current address
    if (property.propertyId === loanModel.$$custom.loan.borrowerSecondary.currentAddressId) {
      loanModel.$$custom.loan.borrowerSecondary.addressCurrent = {
        ...loanModel.$$custom.loan.borrowerSecondary.addressCurrent,
        ...property,
        borrowerId: loanModel.$$custom.loan.borrowerSecondary.borrowerId,
        displayUnitNumber: (loanModel.$$custom.loan.borrowerSecondary.addressCurrent.unitNumber && loanModel.$$custom.loan.borrowerSecondary.addressCurrent.unitNumber.length > 0) ? '#' + loanModel.$$custom.loan.borrowerSecondary.addressCurrent.unitNumber : '',
        displayStreetName: (loanModel.$$custom.loan.borrowerSecondary.addressCurrent.streetName && loanModel.$$custom.loan.borrowerSecondary.addressCurrent.streetName.length > 0) ? loanModel.$$custom.loan.borrowerSecondary.addressCurrent.streetName : ''
      };
    }

    // Secondary borrower mailing address
    if (property.propertyId === loanModel.$$custom.loan.borrowerSecondary.mailingAddressId) {
      loanModel.$$custom.loan.borrowerSecondary.addressMailing = {
        ...loanModel.$$custom.loan.borrowerSecondary.addressMailing,
        ...property,
        borrowerId: loanModel.$$custom.loan.borrowerSecondary.borrowerId,
      };
    }

    // Secondary borrower previous address
    if (
      loanModel.$$custom.loan.borrowerSecondary.previousAddressIds &&
      loanModel.$$custom.loan.borrowerSecondary.previousAddressIds.length > 0
    ) {
      if (property.propertyId === loanModel.$$custom.loan.borrowerSecondary.previousAddressIds[0]) {
        loanModel.$$custom.loan.borrowerSecondary.addressHistory = {
          ...loanModel.$$custom.loan.borrowerSecondary.addressHistory,
          ...property,
          borrowerId: loanModel.$$custom.loan.borrowerSecondary.borrowerId,
          displayUnitNumber: (loanModel.$$custom.loan.borrowerSecondary.addressHistory.unitNumber && loanModel.$$custom.loan.borrowerSecondary.addressHistory.unitNumber.length > 0)
            ? '#' + loanModel.$$custom.loan.borrowerSecondary.addressHistory.unitNumber : '',
          displayStreetName: (loanModel.$$custom.loan.borrowerSecondary.addressHistory.streetName && loanModel.$$custom.loan.borrowerSecondary.addressHistory.streetName.length > 0)
            ? loanModel.$$custom.loan.borrowerSecondary.addressHistory.streetName : ''
        };
      }
    }
    //MultiFamily - 2009 - Two-to-Four
    if (loanModel.urlaFormType === URLAFormTypeEnum.URLA2009 && property.propertyType === 1 && property.numberOfUnits > 1) {
           loanModel.$$custom.loan.addressSubject.propertyType = 88
      }
  });

  loanModel.$$custom.loan.addressSubject.purchaseDate = adjustDate(loanModel.$$custom.loan.addressSubject.purchaseDate);

  loanModel.$$custom.loan.borrowerPrimary.isAddressSameAsPrimaryBorrower = false;
  loanModel.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent =
    loanModel.$$custom.loan.borrowerPrimary.addressMailing.isSameMailingAsBorrowerCurrentAddress === true;

  loanModel.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower =
    loanModel.$$custom.loan.borrowerSecondary.addressCurrent.isSameAsPrimaryBorrowerCurrentAddress === true ||
    (loanModel.$$custom.loan.borrowerSecondary.addressCurrent.isSameMailingAsBorrowerCurrentAddress !== true &&
      areAddressesEqual(
        loanModel.$$custom.loan.borrowerPrimary.addressCurrent,
        loanModel.$$custom.loan.borrowerSecondary.addressCurrent,
      ));

  loanModel.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent =
    loanModel.$$custom.loan.borrowerSecondary.addressCurrent.isSameAsPrimaryBorrowerCurrentAddress === true ||
    loanModel.$$custom.loan.borrowerSecondary.addressMailing.isSameMailingAsBorrowerCurrentAddress === true;

  loanModel.$$custom.additionalREOsPreVal =
    loanModel.transactionInfo.borrowers[0].borrowerDetail.additionalPropertiesOwned;
  loanModel.$$custom.additionalREOsPreValSpouse =
    loanModel.transactionInfo.borrowers[1].borrowerDetail.additionalPropertiesOwned;

  /**
  const propertiesSrc = [
    loanModel.$$custom.loan.addressSubject,
    loanModel.$$custom.loan.borrowerPrimary.addressCurrent,
    loanModel.$$custom.loan.borrowerPrimary.addressMailing,
    loanModel.$$custom.loan.borrowerPrimary.addressHistory,
    loanModel.$$custom.loan.borrowerSecondary.addressCurrent,
    loanModel.$$custom.loan.borrowerSecondary.addressMailing,
    loanModel.$$custom.loan.borrowerSecondary.addressHistory,
  ];

  console.log('L2F Properties', loanModel.properties);
  console.log('cvProperties', propertiesSrc);
  */
}

/**
 * @param loanModel
 * @param models
 */
function mapAssetsToFormBuilder(loanModel: ILoanViewModel, models: { [key: string]: any }) {
  /**
   * Loop through borrowers
   */
  loanModel.transactionInfo.borrowers.forEach(borrower => {
    // console.log('L2F Assets', borrower.assets);

    // If borrower has assets, add to main assets array
    if (borrower.assets.length) {
      loanModel.$$custom.loan.assets.push(...borrower.assets);
      // loanModelMapped.$$custom.loan.assets = [...loanModelMapped.$$custom.loan.assets, ...borrower.assets];
    }
  });

  // Try to find drop down asset with value
  let downPaymentAsset = loanModel.$$custom.loan.assets.find(x => x.assetType === AssetTypeEnum.Other &&
    x.isDownPayment === true && x.assetValue);
  if (!downPaymentAsset) {
    // Try to find blank drop down asset
    downPaymentAsset = loanModel.$$custom.loan.assets.find(x => x.assetType === AssetTypeEnum.Other &&
      x.isDownPayment === true);
  }
  // If drop down asset is found, populate UI view models
  if (downPaymentAsset) {
    loanModel.$$custom.loan.downPaymentAsset = downPaymentAsset;
    loanModel.$$custom.loan.addressSubject.downPayment = downPaymentAsset.assetValue;
  }

  // Strip any null or empty assets out
  loanModel.$$custom.loan.assets = loanModel.$$custom.loan.assets.filter((asset: IAssetViewModel) => {
    if (
      asset.assetType === null ||
      asset.assetType < 0 ||
      [AssetTypeEnum.Automobile, AssetTypeEnum.LifeInsuranceCashValue].includes(asset.assetType)
    ) {
      return false;
    }

    // Type 14 is the downpayment for a purchase, it should not go in the assets array
    if (
      asset.assetType === AssetTypeEnum.Other &&
      asset.isDownPayment === true
    ) {
      return false;
    }

    if (asset.jointAccount) {
      asset.ownerId = 'joint';
    }

    if (asset.monthlyAmount === 0) {
      asset.monthlyAmount = null;
    }

    if (asset.isRemoved) {
      asset.forDelete = true;
    }

    return true;
  });

  // Add in a default asset if no defaults
  if (!loanModel.$$custom.loan.assets.length) {
    const defaultAssets = cloneDeep(models['loan.transactionInfo.borrowers.assets']);
    loanModel.$$custom.loan.assets = [defaultAssets];
  }

  // If owner ID is NOT set, default ownerID to first borrower
  loanModel.$$custom.loan.assets.forEach(asset => {
    if (!asset.ownerId) {
      asset.ownerId = loanModel.transactionInfo.borrowers[0].borrowerId;
    }
  });

  // console.log('cvAssets', loanModel.$$custom.loan.assets);
}

/**
 * Maps employments and incomes (and any interactions) from the loan model
 * to a flat internal model for easier form-binding
 *
 * @param loanModel
 * @param models
 */
function mapEmploymentsToFormBuilder(loanModel: ILoanViewModel, models: { [key: string]: any }) {
  let cvEmployments: ICPOSEmployment[] = [];
  let existingEmployments = new Set();
  // Loop over employment-type incomes
  loanModel.transactionInfo.employments.forEach(loanEmployment => {
    // Ignore employments with no types, regardless of what other data may be filled out
    if (loanEmployment.employmentTypeId === null || loanEmployment.employmentTypeId < 0) {
      return;
    }

    // New, internal Clover employment object
    const cvEmployment: ICPOSEmployment = cloneDeep(models['loan.$$custom.loan.employments']);
    cvEmployment.preserveOnTwnFilter = true;
    // Carry-over existing ids
    if (loanEmployment.isRemoved) {
      cvEmployment.forDelete = true;
    }
    cvEmployment.employmentInfoId = loanEmployment.employmentInfoId;
    cvEmployment.borrowerId = loanEmployment.borrowerId || loanModel.transactionInfo.borrowers[0].borrowerId;

    // Build the income matrix from incomes that reference this employment in the loan model
    const loanEmploymentIncomes: IIncomeInfoViewModel[] = loanModel.transactionInfo.incomes.filter(
      (income: IIncomeInfoViewModel) => {
        return income.employmentInfoId === loanEmployment.employmentInfoId ? true : false;
      },
    );

    if (loanEmploymentIncomes.length > 0) {
      cvEmployment.income.incomeMatrix = loanEmploymentIncomes;
    } else {
      cvEmployment.income.incomeMatrix = cloneDeep(models['loan.transactionInfo.borrowers.incomeMatrix']);
    }

    /**
     * eVerified employments
     */
    if (loanEmployment.employmentVerificationEmploymentId !== null) {
      cvEmployment.isEVerified = true;
      cvEmployment.employmentVerificationEmploymentId = loanEmployment.employmentVerificationEmploymentId;
      cvEmployment.isPrevious = !loanEmployment.isPresent;
    }
    cvEmployment.notes = loanEmployment.notes;

    // Used for specific employment income type look-up
    const loanEmploymentIncomeMap = keyBy(cvEmployment.income.incomeMatrix, 'incomeTypeId');

    // Based on employment type, data gets filled out differently
    if (loanEmployment.employmentTypeId === EmploymentTypeEnum.SalariedEmployee) {
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.Employed;

      /**
       * Fill out salaried-employee-specific properties
       */
      cvEmployment.employerInfo.name = loanEmployment.name;
      cvEmployment.employerInfo.address = loanEmployment.address.streetName;
      cvEmployment.employerInfo.city = loanEmployment.address.cityName;
      cvEmployment.employerInfo.state = loanEmployment.address.stateName;
      cvEmployment.employerInfo.zip = loanEmployment.address.zipCode;
      cvEmployment.employerInfo.phone = loanEmployment.businessPhone;
      cvEmployment.employerInfo.unitNumber = loanEmployment.address.unitNumber;

      cvEmployment.positionInfo.title = loanEmployment.positionDescription;
      cvEmployment.positionInfo.dateStart = adjustDate(loanEmployment.employmentStartDate);
      cvEmployment.positionInfo.dateEnd = adjustDate(loanEmployment.employmentEndDate);
      if (loanEmployment.employmentStatusId === EmploymentStatusTypeEnum.Previous) {
        cvEmployment.isPrevious = true;
      }
      cvEmployment.positionInfo.yearsInPosition = loanEmployment.yearsInThisProfession
        ? '' + loanEmployment.yearsInThisProfession
        : null;
      cvEmployment.positionInfo.monthsInPosition = loanEmployment.monthsInThisProfession;
      cvEmployment.isSpecialBorrowerEmployerRelationship = loanEmployment.specialBorrowerEmployerRelationship === SelectOneYesNoEnum.Yes ? true : false;
      cvEmployment.isForeign = loanEmployment.isForeign;
      cvEmployment.isSeasonal = loanEmployment.isSeasonal;

      /**
       * Fill out salaried-employee-specific incomes
       */

      const baseIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.BaseEmployment];
      if (baseIncome) {
        cvEmployment.income.basePaymentPeriodId = baseIncome.preferredPaymentPeriodId;
        cvEmployment.income.baseAmount = baseIncome.amount || 0;
        cvEmployment.income.baseHoursPerWeek = baseIncome.hourPerWeek;
        if (baseIncome.preferredPaymentPeriodId === PeriodTypeEnum.Hourly) {
          cvEmployment.income.baseAmount = baseIncome.payPerHour;
        }
      }

      const monthsPerYear = 12;
      const overtimeIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.Overtime];
      if (overtimeIncome) {
        cvEmployment.income.annualOvertime = ((overtimeIncome.calculatedMonthlyAmount || 0) * monthsPerYear) || overtimeIncome.amount || 0;
      }

      const bonusesIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.Bonuses];
      if (bonusesIncome) {
        cvEmployment.income.annualBonus = ((bonusesIncome.calculatedMonthlyAmount || 0) * monthsPerYear) || bonusesIncome.amount || 0;
      }

      const commissionsIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.Commissions];
      if (commissionsIncome) {
        cvEmployment.income.annualCommission = ((commissionsIncome.calculatedMonthlyAmount || 0) * monthsPerYear) || commissionsIncome.amount || 0;
      }
    } else if (loanEmployment.employmentTypeId === EmploymentTypeEnum.SelfEmployed) {
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.SelfEmployed;

      /**
       * Fill out self-employment-specific properties
       */
      cvEmployment.employerInfo.name = loanEmployment.name;
      cvEmployment.employerInfo.address = loanEmployment.address.streetName;
      cvEmployment.employerInfo.city = loanEmployment.address.cityName;
      cvEmployment.employerInfo.state = loanEmployment.address.stateName;
      cvEmployment.employerInfo.zip = loanEmployment.address.zipCode;
      cvEmployment.employerInfo.phone = loanEmployment.businessPhone;

      cvEmployment.employerInfo.unitNumber = loanEmployment.address.unitNumber;

      switch (loanEmployment.legalEntityType) {
        case SelfEmploymentEntityTypeEnum.CCorp:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.CCorp;
          break;
        case SelfEmploymentEntityTypeEnum.Partnership:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.Partnership;
          break;
        case SelfEmploymentEntityTypeEnum.SCorp:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.SCorp;
          break;
        case SelfEmploymentEntityTypeEnum.SoleProprietorship:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.SoleProprietorship;
          break;
        case SelfEmploymentEntityTypeEnum.LLCCCorp:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.LLC;
          cvEmployment.employerInfo.cloverLegalEntityLLC = SelfEmploymentCloverEntityTypeLLCEnum.CCorp;
          break;
        case SelfEmploymentEntityTypeEnum.LLCPartnership:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.LLC;
          cvEmployment.employerInfo.cloverLegalEntityLLC = SelfEmploymentCloverEntityTypeLLCEnum.Partnership;
          break;
        case SelfEmploymentEntityTypeEnum.LLCSCorp:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.LLC;
          cvEmployment.employerInfo.cloverLegalEntityLLC = SelfEmploymentCloverEntityTypeLLCEnum.SCorp;
          break;
        case SelfEmploymentEntityTypeEnum.LLCSoleProprietorship:
          cvEmployment.employerInfo.cloverLegalEntityType = SelfEmploymentCloverEntityTypeEnum.LLC;
          cvEmployment.employerInfo.cloverLegalEntityLLC = SelfEmploymentCloverEntityTypeLLCEnum.SoleProprietorship;
          break;
      }

      cvEmployment.positionInfo.title = loanEmployment.positionDescription;
      cvEmployment.positionInfo.dateStart = adjustDate(loanEmployment.employmentStartDate);
      cvEmployment.positionInfo.dateEnd = adjustDate(loanEmployment.employmentEndDate);
      if (loanEmployment.employmentStatusId === EmploymentStatusTypeEnum.Previous) {
        cvEmployment.isPrevious = true;
      }
      cvEmployment.positionInfo.yearsInPosition = loanEmployment.yearsInThisProfession
        ? '' + loanEmployment.yearsInThisProfession
        : null;
      cvEmployment.positionInfo.monthsInPosition = loanEmployment.monthsInThisProfession;
      cvEmployment.positionInfo.OwnershipInterestType = loanEmployment.ownershipInterestType
        ? loanEmployment.ownershipInterestType
        : 1;

      /**
       * Fill out self-employment-specific incomes
       */

      const baseIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.SelfEmployedIncome];
      if (baseIncome) {
        cvEmployment.income.basePaymentPeriodId = baseIncome.preferredPaymentPeriodId;
        if (baseIncome.amount < 0) {
          cvEmployment.income.baseAmount = -1 * baseIncome.amount;
          cvEmployment.income.isLoss = true;
        } else {
          cvEmployment.income.baseAmount = baseIncome.amount || 0;
        }
        cvEmployment.income.baseHoursPerWeek = baseIncome.hourPerWeek;
        if (baseIncome.preferredPaymentPeriodId === PeriodTypeEnum.Hourly) {
          cvEmployment.income.baseAmount = baseIncome.payPerHour;
        }
      }

      const overtimeIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.Overtime];
      if (overtimeIncome) {
        cvEmployment.income.annualOvertime = overtimeIncome.amount || 0;
      }

      const bonusesIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.Bonuses];
      if (bonusesIncome) {
        cvEmployment.income.annualBonus = bonusesIncome.amount || 0;
      }

      const commissionsIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.Commissions];
      if (commissionsIncome) {
        cvEmployment.income.annualCommission = commissionsIncome.amount || 0;
      }
    } else if (loanEmployment.employmentTypeId === EmploymentTypeEnum.ActiveMilitaryDuty) {
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.MilitaryPay;

      /**
       * Fill out military-specific properties
       */
      cvEmployment.employerInfo.address = loanEmployment.address.streetName;
      cvEmployment.employerInfo.city = loanEmployment.address.cityName;
      cvEmployment.employerInfo.state = loanEmployment.address.stateName;
      cvEmployment.employerInfo.zip = loanEmployment.address.zipCode;
      cvEmployment.employerInfo.phone = loanEmployment.businessPhone;

      cvEmployment.positionInfo.branch = +loanEmployment.branchOfService;
      cvEmployment.positionInfo.rank = loanEmployment.positionDescription;
      cvEmployment.positionInfo.dateStart = adjustDate(loanEmployment.employmentStartDate);
      cvEmployment.positionInfo.dateEnd =
        loanEmployment.employmentStatusId === EmploymentStatusTypeEnum.Previous
          ? adjustDate(loanEmployment.employmentEndDate)
          : null;

      if (loanEmployment.employmentStatusId !== EmploymentStatusTypeEnum.Previous) {
        if (loanModel.transactionInfo.borrowers[0].borrowerId === cvEmployment.borrowerId) {
          cvEmployment.positionInfo.expirationOfService = adjustDate(
            loanModel.transactionInfo.borrowers[0].expirationOfService,
          );
        } else {
          cvEmployment.positionInfo.expirationOfService = adjustDate(
            loanModel.transactionInfo.borrowers[1].expirationOfService,
          );
        }
      }

      if (loanEmployment.employmentStatusId === EmploymentStatusTypeEnum.Previous) {
        cvEmployment.isPrevious = true;
      }
      cvEmployment.positionInfo.yearsInPosition = loanEmployment.yearsInThisProfession
        ? '' + loanEmployment.yearsInThisProfession
        : null;
      cvEmployment.positionInfo.monthsInPosition = loanEmployment.monthsInThisProfession;

      /**
       * Fill out military-specific incomes
       */

      const baseIncome: IIncomeInfoViewModel = loanEmploymentIncomeMap[IncomeTypeEnum.MilitaryBasePay];
      if (baseIncome) {
        cvEmployment.income.basePaymentPeriodId = baseIncome.preferredPaymentPeriodId;
        cvEmployment.income.baseAmount = baseIncome.amount || 0;
        cvEmployment.income.baseHoursPerWeek = baseIncome.hourPerWeek;
        if (baseIncome.preferredPaymentPeriodId === PeriodTypeEnum.Hourly) {
          cvEmployment.income.baseAmount = baseIncome.payPerHour;
        }
      }
    } else if (loanEmployment.employmentTypeId === EmploymentTypeEnum.Retired) {
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.PensionRetirement;

      /**
       * Fill out retirement-specific properties
       */
      cvEmployment.income.retirementStartDate = adjustDate(loanEmployment.employmentStartDate);
      cvEmployment.income.retirementEndDate = adjustDate(loanEmployment.employmentEndDate);
      cvEmployment.employerInfo.name = loanEmployment.name;
      cvEmployment.employerInfo.address = loanEmployment.address.streetName;
      cvEmployment.employerInfo.city = loanEmployment.address.cityName;
      cvEmployment.employerInfo.state = loanEmployment.address.stateName;
      cvEmployment.employerInfo.zip = loanEmployment.address.zipCode;
      cvEmployment.employerInfo.phone = loanEmployment.businessPhone;
      if (loanEmployment.employmentStatusId === EmploymentStatusTypeEnum.Previous) {
        cvEmployment.isPrevious = true;
      }

      /**
       * Fill out retirement-specific income
       * In this case, the income does not come from the income matrix, we have to look for
       * the RetirementPensionIncome type in "other" incomes
       */

      if (cvEmployment.isEVerified) {
        const retirementIncomes: IIncomeInfoViewModel[] = loanModel.transactionInfo.incomes.filter(loanIncome => {
          if (
            loanIncome.incomeTypeId === IncomeTypeEnum.RetirementPensionIncome &&
            loanIncome.employmentInfoId == null
          ) {
            loanIncome.employmentInfoId = loanEmployment.employmentInfoId;
            return true;
          }
        });
        retirementIncomes.forEach(income => {
          if (
            income.employmentVerificationIncome &&
            cvEmployment.employmentVerificationEmploymentId ===
              income.employmentVerificationIncome.employmentVerificationEmploymentId
          ) {
            cvEmployment.incomeInfoId = income.incomeInfoId;
            const monthlyPension = income.calculatedMonthlyAmount.toFixed(2);
            cvEmployment.income.monthlyPension = +monthlyPension;
          }
        });
      } else {
        const retirementIncome: IIncomeInfoViewModel = loanModel.transactionInfo.incomes.find(loanIncome => {
          return (
            loanIncome.incomeTypeId === IncomeTypeEnum.RetirementPensionIncome &&
            cvEmployment.borrowerId === loanIncome.borrowerId
          );
        });

        if (retirementIncome) {
          cvEmployment.incomeInfoId = retirementIncome.incomeInfoId;
          const monthlyPension = retirementIncome.calculatedMonthlyAmount.toFixed(2);
          cvEmployment.income.monthlyPension = +monthlyPension;
        }
      }
    } else if (loanEmployment.employmentTypeId === EmploymentTypeEnum.OtherOrUnemployed) {
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.NoIncome;

      if (loanEmployment.employmentStatusId === EmploymentStatusTypeEnum.Previous) {
        cvEmployment.isPrevious = true;
      }
    }

    // If we found a mapping, add to internal list of employments
    if (
      cvEmployment.cvIncomeTypeId !== null &&
      (!existingEmployments.has(cvEmployment.incomeInfoId) || cvEmployment.incomeInfoId == null)
    ) {
      cvEmployments.push(cvEmployment);
      !!cvEmployment.incomeInfoId && cvEmployment.cvIncomeTypeId == CPOSIncomeTypeEnum.PensionRetirement
        ? existingEmployments.add(cvEmployment.incomeInfoId)
        : null;
    }
  });

  // Loop over income-type incomes
  loanModel.transactionInfo.incomes.forEach(loanIncome => {
    // Ignore incomes with no types, regardless of what other data may be filled out
    if (loanIncome.incomeTypeId === null || loanIncome.incomeTypeId < 0) {
      return;
    }

    // New, internal Clover employment object
    const cvEmployment: ICPOSEmployment = cloneDeep(models['loan.$$custom.loan.employments']);

    // Carry-over existing ids
    if (loanIncome.isRemoved === true) {
      cvEmployment.forDelete = true;
    }
    cvEmployment.incomeInfoId = loanIncome.incomeInfoId;
    cvEmployment.borrowerId = loanIncome.borrowerId || loanModel.transactionInfo.borrowers[0].borrowerId;

    if (loanIncome.incomeTypeId === IncomeTypeEnum.SocialSecurity) {
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.SocialSecurity;

      /**
       * Fill out social-security-specific properties
       */

      cvEmployment.income.monthlySocialSecurityPay = loanIncome.amount || 0;
    } else if (
      [
        IncomeTypeEnum.AlimonyChildSupport,
        IncomeTypeEnum.ChildSupport,
        IncomeTypeEnum.AutomobileExpenseAccount,
        IncomeTypeEnum.BoarderIncome,
        IncomeTypeEnum.CapitalGains,
        IncomeTypeEnum.EmploymentRelatedAssets,
        IncomeTypeEnum.ForeignIncome,
        IncomeTypeEnum.FosterCare,
        IncomeTypeEnum.HousingOrParsonage,
        IncomeTypeEnum.DividendsInterest,
        IncomeTypeEnum.DisabilityIncome,
        IncomeTypeEnum.MiscBusinessIncome,
        IncomeTypeEnum.MortgageCreditCertificate,
        IncomeTypeEnum.RealEstateMortgageDifferential,
        IncomeTypeEnum.NotesReceivableInstallment,
        IncomeTypeEnum.PublicAssistance,
        IncomeTypeEnum.RoyaltyPayment,
        IncomeTypeEnum.SeasonalIncome,
        IncomeTypeEnum.SeparateMaintenance,
        IncomeTypeEnum.TemporaryLeave,
        IncomeTypeEnum.TipIncome,
        IncomeTypeEnum.TrustIncome,
        IncomeTypeEnum.VABenefitsNonEducational,
        IncomeTypeEnum.DividendsInterest,
        IncomeTypeEnum.Unemployment,
        IncomeTypeEnum.Other,
      ].includes(loanIncome.incomeTypeId)
    ) {
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.OtherUnemployed;

      /**
       * Fill out other-income-specific properties
       */

      cvEmployment.income.otherIncomeTypeId = loanIncome.incomeTypeId;
      cvEmployment.income.otherIncomeAmount = loanIncome.amount || 0;
      cvEmployment.income.otherPaymentPeriodId = loanIncome.preferredPaymentPeriodId;
      cvEmployment.income.otherIncomeDescription = loanIncome.description;
      cvEmployment.income.otherPayPerHour = loanIncome.payPerHour || 0;
    } else if (
      loanIncome.incomeTypeId === IncomeTypeEnum.RetirementPensionIncome &&
      loanIncome.employmentInfoId == null
    ) {
      loanIncome.employmentInfoId = loanIncome.borrowerId;
      cvEmployment.income.retirementIncomeOther = true;
      cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.PensionRetirement;
      cvEmployment.income.monthlyPension = loanIncome.amount | 0;
    }

    // If we found a mapping, add to internal list of employments
    if (
      cvEmployment.cvIncomeTypeId !== null &&
      (!existingEmployments.has(cvEmployment.incomeInfoId) || cvEmployment.incomeInfoId == null)
    ) {
      cvEmployments.push(cvEmployment);
      !!cvEmployment.incomeInfoId ? existingEmployments.add(cvEmployment.incomeInfoId) : null;
    }
  });

  // If no employments found, add a default model to the employments array
  if (!cvEmployments.length) {
    // Add default model to employments array
    cvEmployments = [cloneDeep(models['loan.$$custom.loan.employments'])];
  }

  cvEmployments.forEach(cvEmployment => {
    if (!cvEmployment.forDelete && cvEmployment.borrowerId === loanModel.transactionInfo.borrowers[1].borrowerId) {
      loanModel.$$custom.primaryBorrowerIncomeComplete = true;
    }
  });

  // console.log('L2F Employments', loanModel.transactionInfo.employments);
  // console.log('L2F Incomes', loanModel.transactionInfo.incomes);
  // console.log('cvEmployments', cvEmployments);

  loanModel.$$custom.loan.employments = cvEmployments;
}

function mapDeclarationsToFormBuilder(loanModel: ILoanViewModel): void {
  const toFlagsArray = (flags: BankruptcyTypeEnum) => [
    BankruptcyTypeEnum.ChapterSeven,
    BankruptcyTypeEnum.ChapterEleven,
    BankruptcyTypeEnum.ChapterTwelve,
    BankruptcyTypeEnum.ChapterThirteen,
  ].filter(f => (flags & f) === f);

  Object.assign(loanModel.$$custom, {
    // Borrower bankrupticies
    bankrupcyType: toFlagsArray(loanModel.transactionInfo.borrowers[0].declarationsInfo.bankruptcyType),
    // Coborrower bankrupticies
    bankrupcyType2: toFlagsArray(loanModel.transactionInfo.borrowers[1].declarationsInfo.bankruptcyType),
  });
}

function mapAdditionalApplicant(loanModel: ILoanViewModel): void {
  if (loanModel.additionalApplicant) {
    loanModel.$$custom.loan.additionalApplicant = {
      ...loanModel.$$custom.loan.additionalApplicant,
      ...loanModel.additionalApplicant
    };
  }
}

function adjustDob(date: string) {
  if (!date) {
    return date;
  }

  const dateParts = date.split('/');
  return dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
}

function adjustDate(date: string) {
  if (!date || date.indexOf('T') < 0) {
    return date;
  }

  return date.substring(0, date.indexOf('T'));
}

function createCounselingModel(borrowerId: string, counselingType: CounselingTypeEnum): IBorrowerCounselingViewModel {
  let counselingModel = {} as IBorrowerCounselingViewModel;
  counselingModel.agencyType = CounselingProviderTypeEnum.None;
  counselingModel.clusterId = 0;
  counselingModel.borrowerId = borrowerId;
  counselingModel.counselingFormatType = CounselingFormatTypeEnum.None;
  counselingModel.counselingConfirmation = NullableBooleanEnum.SelectOne;
  counselingModel.counselingWorkshopType = CounselingWorkshopTypeEnum.None;
  counselingModel.counselingType = counselingType;
  counselingModel.counselingCompletedDate = null;
  counselingModel.providerAgencyId = null;
  counselingModel.providerAgencyName = null;
  return counselingModel;
}

function getProgramType(borrower: IBorrowerViewModel): number {
  if((borrower.borrowerHousingCounseling.counselingConfirmation == NullableBooleanEnum.SelectOne
    && borrower.borrowerEducationCounseling.counselingConfirmation == NullableBooleanEnum.SelectOne)||
    borrower.borrowerHousingCounseling.counselingConfirmation == null
    && borrower.borrowerEducationCounseling.counselingConfirmation == null) {
    return null;
  }
  if(borrower.borrowerHousingCounseling.counselingConfirmation == NullableBooleanEnum.Yes
    && borrower.borrowerEducationCounseling.counselingConfirmation == NullableBooleanEnum.Yes) {
    return 3; //both
  } else if (borrower.borrowerHousingCounseling.counselingConfirmation != NullableBooleanEnum.Yes
    && borrower.borrowerEducationCounseling.counselingConfirmation != NullableBooleanEnum.Yes)  {
    return  0; //none
  } else {
    return borrower.borrowerHousingCounseling.counselingConfirmation == NullableBooleanEnum.Yes ? 2 : 1; //education 1, housing 2
  }
}
